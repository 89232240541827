import React from "react";
import { StyleSheet, TouchableOpacity, Text, View } from "react-native";

export const navBarHeight = 80;

function AppNavBar({title}) {
  return (
    <View style={styles.container} >
    
        <Text style={styles.text}>
          Maciej Czekala
        </Text>
     
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    width: "100%",
    height: navBarHeight,
    position: 'absolute',
    zIndex: 100, 
    alignItems: 'center',
    justifyContent: 'center' 
  },
  text: {
    textTransform: 'uppercase',
    fontWeight: "bold",
    color: "black"
  }
});

export default AppNavBar;

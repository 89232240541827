const  colors = {
  primary: "red",
  secondary: "blue",
  accent: "#61DAFB",
  gray: "#D4D4D4",
  black: "#2B2B2B",
};

colors.button = {
  text: "white"
}
colors.box = {
  text: colors.black
}
export default colors;

import React from "react";
import { StyleSheet, TouchableOpacity, Text, View } from "react-native";

import colors from "../styles/colors"

function AppButton({title, style, onPress}) {
  return (
    <TouchableOpacity onPress={onPress} >
      <View style={[styles.app,style]} >
        <Text style={styles.text}>
          {title}
        </Text>
      </View>

    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  app: {
    backgroundColor: colors.accent,
    padding: 16,
    paddingHorizontal: 20,
    borderRadius: 8,
    marginHorizontal: "auto",
    minWidth: 256,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  text: {
    color: colors.button.text,
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    // fontFamily:"Trebuchet MS"

  }
});

export default AppButton;

import React from "react";
import { StyleSheet, TouchableOpacity, Text } from "react-native";

function AppText({text, type, style}) {
  if (!type) { type = "p" }

  return (
    <Text style={[styles.base, styles[type], style]}>
      {text}
    </Text>
  );
}

const styles = StyleSheet.create({
  base: {
    color: "black",
    textAlign: 'center',
  },
  p: {
    fontSize: 14,
    // fontFamily: 'Tahoma',
    margin: 2
  },
  h1: {
    fontSize: 34,
    // fontFamily: 'Tahoma',
    fontWeight: "bold",
    margin: 10,
  },
  h2: {
    fontSize: 22,
    // fontFamily: 'Tahoma',
    fontWeight: "bold",
    margin: 8
  },
  h3: {
    fontSize: 18,
    // fontFamily: 'Tahoma',
    margin: 6
  },
  h4: {
    fontSize: 16,
    // fontFamily: 'Tahoma',
    margin: 4
  },
});

export default AppText;

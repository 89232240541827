import React from "react";
import { StyleSheet, Text, View } from "react-native";

import colors from "../styles/colors"

function Box({title, subTitle, style}) {
  return (
    <View style={[styles.app, style]} >
      <Text style={styles.title}>
        {title}
      </Text>
      <Text style={styles.subTitle}>
        {subTitle}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  app: {
    backgroundColor: colors.gray,
    padding: 12,
    paddingHorizontal: 18,
    borderRadius: 8,
    margin: 10,
    // marginHorizontal: "auto",

  },
  title: {
    color: colors.box.text,
    fontSize: 14,
    textAlign: "center",
    textTransform: 'uppercase',
    // fontFamily:"Tahoma"
  },
  subTitle: {
    color: colors.box.text,
    fontSize: 12,
    textAlign: "center",
    marginTop: 4,
    // fontFamily:"Tahoma"
  }
});

export default Box;

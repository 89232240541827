import React from "react";
import { StyleSheet, View } from "react-native";

import colors from "../styles/colors"

function Separator({style}) {

  return (
    <View style={[styles.base, style]}>

    </View>
  );
}

const styles = StyleSheet.create({
  base: {
    backgroundColor: colors.accent,
    alignSelf: 'center', 
    width: 40,
    borderRadius: 3,
    height: 6,
    margin: 10
  },
});

export default Separator;

import React, { useRef } from "react";
import { StyleSheet, View, ScrollView, Linking, Image } from "react-native";

import Button from "./components/Button";
import Box from "./components/Box";
import RowImage from "./components/RowImage";
import NavBar, { navBarHeight } from "./components/NavBar";
import Text from "./components/Text";
import Separator from "./components/Separator";

import ReactGA from "react-ga";
const TRACKING_ID = "G-HRHBWTX4GM"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

//
function App() {
  const year = new Date().getFullYear();
  const scrollRef = useRef(null);
  return (
    <View style={styles.app}>
      <NavBar />
      <ScrollView ref={scrollRef}>
        <View
          style={[
            styles.container,
            { marginTop: navBarHeight, backgroundColor: "#F0F0F0" },
          ]}
        >
          <View style={[styles.section]}>
            <Image
              source={require("./images/me.jpg")}
              style={{
                width: 128,
                height: 128,
                alignSelf: "center",
                borderRadius: 64,
              }}
            />
            <Text text={"WELCOME TO 'MC' PORTFOLIO"} type={"h1"} />
            <Separator />
            <Text
              text={
                "Portfolio page build with React Native Web presenting Maciej Czekala projects and experience."
              }
              type={"h3"}
            />
            <Button
              title="PROJECTS"
              style={{ marginTop: 50 }}
              onPress={() => {
                scrollRef.current.scrollTo({ x: 0, y: 1600, animated: true });
              }}
            />
          </View>
        </View>
        <View style={styles.container}>
          <View style={[styles.section]}>
            <Text text={"ABOUT 'MACIEJ'"} type={"h1"} />
            <Separator />
            <Text
              text={"Building mobile apps since 2010 for iOS and Android. "}
              type={"h3"}
            />
            <Text
              text={
                "Educated in UK at University of Abertay where completed BA (hons) and Masters degree in Video Game Production."
              }
              type={"h3"}
            />
            <Text
              text={
                "Interested in modern technologies and developing new skill."
              }
              type={"h3"}
            />

            <Text text={"TECH SKILLS "} type={"h2"} style={{ marginTop: 50 }} />
            <View
              style={{
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box title={"JavaScript"} subTitle={year - 2015 + " years"} />
              <Box title={"Parse Server"} subTitle={year - 2015 + " years"} />
              <Box title={"React js"} subTitle={year - 2020 + " years"} />
              <Box title={"React Native"} subTitle={year - 2021 + " year"} />
              <Box
                title={"React Native web"}
                subTitle={year - 2021 + " year"}
              />
              <Box title={"GIT"} subTitle={year - 2016 + " years"} />
              <Box title={"OneSignal"} subTitle={year - 2018 + " years"} />
              <Box title={"Stripe"} subTitle={year - 2021 + " years"} />
              <Box title={"Payu, tpay"} subTitle={year - 2020 + " years"} />

              <Box title={"Solar2d"} subTitle={year - 2010 + " years"} />
              <Box title={"Lua"} subTitle={year - 2010 + " years"} />
            </View>

            <Text text={"WORK SKILLS "} type={"h2"} style={{ marginTop: 50 }} />
            <View
              style={{
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box title={"MONDAY.COM"} subTitle={year - 2022 + " years"} />
              <Box title={"JIRA"} subTitle={year - 2017 + " years"} />
              <Box title={"Confluence"} subTitle={year - 2017 + " years"} />
              <Box title={"TRELLO"} subTitle={year - 2014 + " years"} />
              <Box title={"ASANA"} subTitle={year - 2016 + " year"} />
              <Box title={"AGILE"} subTitle={year - 2016 + " years"} />
              <Box title={"GOOGLE PLAY"} subTitle={year - 2010 + " years"} />
              <Box title={"APP STORE"} subTitle={year - 2010 + " years"} />
              <Box title={"TEAM WORK"} subTitle={year - 2011 + " years"} />
              <Box title={"TEAM LEAD"} subTitle={year - 2012 + " years"} />
            </View>
          </View>
        </View>

        <View style={[styles.container, { backgroundColor: "#F0F0F0" }]}>
          <View style={[styles.section]}>
            <Text text={"PROJECTS"} type={"h1"} />
            <Separator />
            <Text
              text={
                "Most important mobile, web and backend projects developed in recent years."
              }
              type={"h3"}
            />

            <Text
              text={"ACTIVE / ALIVE"}
              type={"h2"}
              style={{ marginTop: 50 }}
            />
            <RowImage
              title={"Food Farmer (2024)"}
              image={require("./images/foodfarmer-screen-1.png")}
              subTitle={
                "Listing of local farmers with their products. iOS and Android apps. More than 150k users."
              }
              links={[
                {
                  name: "www",
                  store: "official website",
                  url: "https://foodfarmer.pl/",
                },
                {
                  name: "Android app",
                  store: "googleplay",
                  url: "https://play.google.com/store/apps/details?id=pl.foodfarmer.mobile",
                },
                {
                  name: "iOS app",
                  store: "AppStore",
                  url: "https://apps.apple.com/pl/app/food-farmer/id6497228184",
                }
              ]}
              icons={[
                require("./images/reactnative-logo.png"),
                require("./images/parseserver-logo.png"),
              ]}
            />

            <RowImage
              title={"TaskON (2023)"}
              image={require("./images/taskon-screen-1.png")}
              subTitle={
                "Task manager with automatic review requests to monitor work quality."
              }
              links={[
                {
                  name: "iOS app",
                  store: "internal",
                  url: "",
                },
                {
                  name: "Android app",
                  store: "googleplay",
                  url: "https://play.google.com/store/apps/details?id=pl.taskon.mobile",
                },
                {
                  name: "admin web app",
                  store: "web",
                  url: "https://admin.taskon.pl/",
                },
                {
                  name: "review web app",
                  store: "web",
                  url: "https://o.taskon.pl/",
                },
              ]}
              icons={[
                require("./images/reactnative-logo.png"),
                require("./images/parseserver-logo.png"),
              ]}
            />

            <RowImage
              title={"KinCapsule (2023)"}
              image={require("./images/kincapsule-screen-1.png")}
              subTitle={"Mobile apps for existing memory capsule project."}
              links={[
                {
                  name: "iOS app",
                  store: "appstore",
                  url: "https://apps.apple.com/us/app/kincapsule/id6450718011",
                },
                {
                  name: "Android app",
                  store: "googleplay",
                  url: "https://play.google.com/store/apps/details?id=com.kincapsule.app",
                },
              ]}
              icons={[require("./images/reactnative-logo.png")]}
            />

            <RowImage
              title={"ConsiglioValle.tv (2023)"}
              image={require("./images/consigliovalle-screen-1.png")}
              subTitle={"Mobile iOS and AppleTV apps for existing video streaming project"}
              links={[
                {
                  name: "iOS app",
                  store: "appstore",
                  url: "https://apps.apple.com/us/app/consigliovalle-tv/id6463201331",
                },
                {
                  name: "AppleTV",
                  store: "appstore",
                  url: "https://apps.apple.com/us/app/consigliovalle-tv-tv/id6463211803",
                },
              ]}
              icons={[require("./images/reactnative-logo.png")]}
            />

            <RowImage
              title={"Streaming OTT apps (2021)"}
              image={require("./images/ott-screen-1.png")}
              subTitle={
                "OTT Video Platform: End users apps, Admin panel app and backend."
              }
              links={[
                {
                  name: "iOS Video app",
                  store: "internal",
                  url: "",
                },
                {
                  name: "Android Video app",
                  store: "internal",
                  url: "",
                },
                {
                  name: "Android TV app",
                  store: "internal",
                  url: "",
                },
                {
                  name: "Web Video app",
                  store: "web",
                  url: "https://demo.alterbit.pl",
                },
                {
                  name: "Admin panel",
                  store: "web",
                  url: "https://admin.alterbit.pl",
                },
              ]}
              icons={[
                require("./images/reactjs-logo.png"),
                require("./images/reactnative-logo.png"),
                require("./images/solar2d-logo.png"),
                require("./images/parseserver-logo.png"),
              ]}
            />

            <RowImage
              title={"ZiO food (2020)"}
              image={require("./images/ziofood-screen-1.png")}
              subTitle={
                "Complete Food Delivery Platform including Ordering apps, Restaurant app, Drivers apps, Callcenter app and backend."
              }
              links={[
                {
                  name: "iOS Ordering app",
                  store: "appstore",
                  url: "https://apps.apple.com/pl/app/id1511807312?l=pl",
                },
                {
                  name: "Android Ordering app",
                  store: "googleplay",
                  url: "https://play.google.com/store/apps/details?id=pl.zioapp.customer",
                },
                {
                  name: "Web Ordering app",
                  store: "web",
                  url: "https://ziofood.pl",
                },
                {
                  name: "Custom Web Ordering app",
                  store: "web",
                  url: "https://gongpoznan.zamow.org",
                },
                {
                  name: "Android Restaurant app",
                  store: "googleplay",
                  url: "https://play.google.com/store/apps/details?id=pl.zioapp.customer",
                },
                {
                  name: "Android Driver app",
                  store: "googleplay",
                  url: "https://play.google.com/store/apps/details?id=pl.zioapp.customer",
                },
                {
                  name: "iOS Driver app",
                  store: "appstore",
                  url: "https://play.google.com/store/apps/details?id=pl.zioapp.customer",
                },
                {
                  name: "Web restaurant app",
                  store: "web",
                  url: "https://biz.ziofood.pl",
                },
                {
                  name: "Web Driver app",
                  store: "web",
                  url: "https://delivery.ziofood.pl",
                },
                { name: "Windows Callcenter app", store: "internal", url: "" },
              ]}
              icons={[
                require("./images/reactjs-logo.png"),
                require("./images/solar2d-logo.png"),
                require("./images/parseserver-logo.png"),
              ]}
            />

            <RowImage
              title={"Powiadomka"}
              image={require("./images/powiadomka-screen-1.png")}
              subTitle={
                "Delivery Arrival notification Platform including User apps, Business app and backend."
              }
              links={[
                {
                  name: "iOS user app",
                  store: "appstore",
                  url: "https://apps.apple.com/pl/app/id1373845338",
                },
                {
                  name: "Android user app",
                  store: "googleplay",
                  url: "https://play.google.com/store/apps/details?id=pl.alterbit.letmeknow.pl",
                },
                {
                  name: "Android biz app",
                  store: "googleplay",
                  url: "https://play.google.com/store/apps/details?id=pl.alterbit.letmeknowbiz.pl",
                },
              ]}
              icons={[
                require("./images/solar2d-logo.png"),
                require("./images/parseserver-logo.png"),
              ]}
            />

            <Text text={"LEGACY"} type={"h2"} style={{ marginTop: 50 }} />

            <RowImage
              title={"BeActiveTV - fitness app (2018)"}
              image={require("./images/beactivetv-screen-1.jpg")}
              subTitle={
                "Fitness Video app. iOS, Android, AndroidTV. Not available anymore."
              }
              links={[]}
              icons={[require("./images/solar2d-logo.png")]}
            />

            <RowImage
              title={"Canal + sport online - video app (2016)"}
              image={require("./images/canalplussport-screen-1.png")}
              subTitle={
                "Sports Video app. iOS, Android. Not available anymore."
              }
              links={[]}
              icons={[require("./images/solar2d-logo.png")]}
            />

            <RowImage
              title={"Lech Poznan Mobile - news and statistics app (2012)"}
              image={require("./images/lechpoznan-screen-1.png")}
              subTitle={
                "Mobile app for Lech Poznan football club. Won Mobile Trends Awards in 2012. iOS, Android. Not available anymore."
              }
              links={[]}
              icons={[require("./images/solar2d-logo.png")]}
            />

            <Text text={"OTHER"} type={"h2"} style={{ marginTop: 50 }} />

            <RowImage
              title={"Mojelinki.online - quick links creator (2021)"}
              image={require("./images/mojelinki-screen-1.jpg")}
              subTitle={"Experimental serwis with quick links pages."}
              links={[]}
              icons={[
                require("./images/reactjs-logo.png"),
                require("./images/parseserver-logo.png"),
              ]}
            />
          </View>
        </View>

        <View style={[styles.container]}>
          <View style={[styles.section]}>
            <Text text={"Maciej Czekala"} type={"h1"} />
            <Separator />
            <Text text={"Mobile, Web and Backend developer"} type={"h3"} />
            <Box title="maciej@czekala.com" style={{ marginTop: 50 }} />
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  app: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "center",
    minHeight: "auto",
    paddingTop: 120,
    paddingBottom: 120,

    // justifyContent: 'center'
  },
  section: {
    flex: 1,
    maxWidth: 720,
  },
});

export default App;

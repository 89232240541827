import React from "react";
import { StyleSheet, Text, View, Image, Linking } from "react-native";

import colors from "../styles/colors"

function RowImage({title, subTitle, links, image, icons, style}) {
  return (
    <View style={[styles.container, style]} >
      <View style={styles.left} >
        <Image style={styles.image} source={image} />
      </View>
      <View style={styles.right} >
        <Text style={styles.title}>
          {title}
        </Text>
        <Text style={styles.subTitle}>
          {subTitle}
        </Text>

        {links.map((item) => (
          <Text style={styles.link} key={item.name}>
            <Text  >• {item.name}</Text> | <Text style={{textDecorationLine: 'underline' }} onPress={()=>{Linking.openURL(item.url);}}>{item.store}</Text>
          </Text>
        ) )}
        <View style={{flexDirection: 'row' }} >
          {icons.map((item) => (
            <Image key={item} style={styles.icon} source={item} />
          ) )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: 32
  },
  left: {
    flex: 1,
    // backgroundColor:"red"
  },
  right: {
    flex: 2,
    marginTop: 30
  },
  image: {
    // width: "50%",
    height: 320,
    resizeMode: "contain",
  },
  icon: {
    width: 50,
    height: 50,
    resizeMode: "contain",
    margin: 4,
    marginTop: 10
  },
  title: {
    color: colors.box.text,
    fontSize: 18,
    textAlign: "left",
    textTransform: 'uppercase',
    // fontFamily:"Tahoma"
  },
  subTitle: {
    color: colors.box.text,
    fontSize: 14,
    textAlign: "left",
    marginTop: 6,
    marginBottom: 10,
    // fontFamily:"Tahoma"
  },
  link:{
    fontSize: 14,
    // fontFamily:"Tahoma",
    color:"gray"
  }
});

export default RowImage;
